<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { mapActions, mapGetters } from 'vuex'
import "prismjs";
import "prismjs/themes/prism.css";
import {
  required,
  email,
  helpers,
  numeric,
  maxLength
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
// import SignaturePad from "signature_pad"


export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isSubmited: false,
      loader: false,
      disabled: false,
      baseURl: null,
      user: {
        name: "",
        email: "",
        phone: "",
        qualification: '',
        date_of_birth: '',
        language: "english",
        visible_password: "",
        role_id: null,
        business_name: "",
        business_type: "",
        bank_account: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "india",
        years_of_experience: "",
        passport_photo: '',
        passport_photo_url: '',
        aadhar_card: '',
        aadhar_card_url: '',
        tier: "",
        pan_name: '',
        gst_number: '',
        pan_number: '',
        bio: '',
        consultancy_charge_fifthteen: "",
        consultancy_charge_thirty: "",
        consultancy_charge_forty: "",
        skills: [],
        industries: [],
        isUpdateSignature: false,
        remark: '',
        signature_photo: '',
        qa_doc: ''
      },
      // signature: null
    };
  },
  validations: {
    user: {
      name: {
        required: helpers.withMessage("Name is required", required),
      },
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      phone: {
        required: helpers.withMessage("Phone is required", required),
        numeric: helpers.withMessage("Please enter only numbers", numeric),
      },
      visible_password: {
        required: helpers.withMessage("Password is required", required),
      },
      role_id: {
        required: helpers.withMessage("Role is required", required),
      },
      country: {
        required: helpers.withMessage("Country is required", required),
      },
      years_of_experience: {
        required: helpers.withMessage("City is required", required),
      },
      pan_name: {
        required: helpers.withMessage("PAN name is required", required),
      },
      gst_number: {
        required: helpers.withMessage("GST no is required", required),
      },
      pan_number: {
        required: helpers.withMessage("PAN no is required", required),
      },
      bio: {
        required: helpers.withMessage("Bio is required", required),
        maxLength: helpers.withMessage("Maximum 700 Characters", maxLength(700)),
      },
      qualification: {
        required: helpers.withMessage("Qualification is required", required),
      },
      date_of_birth: {
        required: helpers.withMessage("Date of birth is required", required),
      },
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect
  },
  computed: {
    ...mapGetters({
      getSkills: "skills/getSkills",
      getIndustries: "industry/getIndustry",
      getServices: "service/getServices",
      userData: "auth/user",
    }),
    skillsOptions() {
      var options = []
      this.getSkills.map(s => {
        var payload = {
          value: s.skills_id,
          label: s.skill_name,
        }
        options.push(payload)
      })
      return options
    },
    industryOptions() {
      var options = []
      this.getIndustries.map(i => {
        var payload = {
          value: i.industries_id,
          label: i.industries_name,
        }
        options.push(payload)
      })
      return options
    },
    serviceOptions() {
      var options = []
      this.getServices.map(i => {
        var payload = {
          value: i.id,
          label: i.title,
        }
        options.push(payload)
      })
      return options
    },
  },
  methods: {
    ...mapActions({
      addUserDb: 'users/addUser',
      getPartnerById: 'users/getPartnerById',
      updateUserDb: 'users/updateUser',
      setSkills: "skills/setSkills",
      setIndustries: "industry/setIndustry",
      updatePartner: 'auth/updatePartner',
      setServices: "service/setServices",
    }),
    updateUser() {
      console.log('submit');
      this.isSubmited = true;
      this.v$.user.$touch();
      if (this.v$.user.$invalid) {
        return;
      }
      this.loader = true
      this.disabled = true
      var formdata = new FormData();
      formdata.append('id', this.user.id);
      formdata.append('name', this.user.name);
      formdata.append('email', this.user.email);
      formdata.append('password', this.user.visible_password);
      formdata.append('phone', this.user.phone);
      formdata.append('qualification', this.user.qualification);
      formdata.append('date_of_birth', this.user.date_of_birth);
      formdata.append('city', this.user.city);
      formdata.append('state', this.user.state);
      formdata.append('country', this.user.country);
      formdata.append('address', this.user.address);
      formdata.append('zip', this.user.zip);
      formdata.append('years_of_experience', this.user.years_of_experience);
      formdata.append('role_id', this.user.role_id);
      formdata.append('passport_photo', this.user.passport_photo);
      formdata.append('pan_photo', this.user.pan_photo);
      formdata.append('gst_photo', this.user.gst_photo);
      formdata.append('aadhar_card', this.user.aadhar_card);
      formdata.append('consultancy_charge_fifthteen', this.user.consultancy_charge_fifthteen);
      formdata.append('consultancy_charge_thirty', this.user.consultancy_charge_thirty);
      formdata.append('consultancy_charge_forty', this.user.consultancy_charge_forty);
      formdata.append('skills', JSON.stringify(this.user.skills));
      formdata.append('industries', JSON.stringify(this.user.industries));
      formdata.append('services', JSON.stringify(this.user.services));

      formdata.append('bio', this.user.bio);
      formdata.append('pan_no', this.user.pan_number);
      formdata.append('pan_name', this.user.pan_name);
      formdata.append('gst_no', this.user.gst_number);
      formdata.append('signature', this.user.signature_photo);
      formdata.append('remark', this.user.remark);
      formdata.append('qa_doc', this.user.qa_doc);

      if (this.user.tier)
        formdata.append('tier', this.user.tier);
      // if (!this.signature.isEmpty()) {
      //   var signatureData = this.signature.toDataURL('image/png');
      //   formdata.append('signature', signatureData);
      // }
      this.updatePartner(formdata).then((res) => {
        this.loader = false
        this.disabled = false
        console.log('resss', res)
        if (res.status) {
          this.$toast.open({
            message: "Partner updated successfully.",
            type: "success",
          })
          this.$router.push({ name: "Users" });
        } else {
          let message = res.data.message
          this.$toast.open({
            message: message,
            type: "success",
          })
        }
      }).catch((error) => {
        this.loader = false
        this.disabled = false
        if (error.response && error.response.status == 422 && error.response.data && error.response.data.data.email) {
          this.$toast.open({
            message: 'The email has already been taken.',
            type: "error",
          })
        } else if (error.response && error.response.status == 422 && error.response.data && error.response.data.data.phone) {
          this.$toast.open({
            message: 'The phone has already been taken.',
            type: "error",
          })
        } else {
          this.$toast.open({
            message: 'Server Error',
            type: "error",
          })
        }
      })
    },
    changeFile(e, type) {
      const file = e.target.files[0];
      if (file) {
        if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/svg' || file.type == 'image/svg+xml' || file.type == 'image/gif' || file.type == 'application/pdf') {
          if (type == 'pan') {
            this.user.pan_photo = file
          } else if (type == 'gst') {
            this.user.gst_photo = file
          } else if (type == 'aadhar') {
            this.user.aadhar_card = file
            this.user.aadhar_card_url = URL.createObjectURL(file);
          } else if (type == 'passport') {
            this.user.passport_photo = file
            this.user.passport_photo_url = URL.createObjectURL(file);
          }
          else if (type == 'signature') {
            this.user.signature_photo = file
          }
          else if (type == 'qaDoc') {
            this.user.qa_doc = file
          }
        } else {
          console.log(
            "Error!",
            "Please enter valid image file.",
            "error"
          );
        }
      }
    },
    // changePassportFile(e) {
    //   const file = e.target.files[0];
    //   if (file) {
    //     if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/svg' || file.type == 'image/svg+xml' || file.type == 'image/gif') {
    //       this.user.passport_photo = file
    //       this.user.passport_photo_url = URL.createObjectURL(file);

    //     } else {
    //       console.log(
    //         "Error!",
    //         "Please enter valid image file.",
    //         "error"
    //       );
    //     }
    //   }
    // },

    // changeAadharFile(e) {
    //   const file = e.target.files[0];
    //   if (file) {
    //     if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/svg' || file.type == 'image/svg+xml' || file.type == 'image/gif') {
    //       this.user.aadhar_card = file
    //       this.user.aadhar_card_url = URL.createObjectURL(file);

    //     } else {
    //       console.log(
    //         "Error!",
    //         "Please enter valid image file.",
    //         "error"
    //       );
    //     }
    //   }
    // },

    showSelectedArr(fieldCom, fieldVar) {
      return fieldCom?.filter((singleSkill) => {
        return fieldVar.includes(singleSkill.value)
      })
    }
  },
  mounted() {
    this.baseURl = process.env.VUE_APP_API_URL;

    if (this.$route.params.id) {
      this.getPartnerById(this.$route.params.id).then((res) => {
        if (res.data.status) {
          this.user = res.data.data.user
          this.user.date_of_birth = res.data.data.user.date_of_birth.substr(0, res.data.data.user.date_of_birth.indexOf(' '))
          if (!this.user.skills) this.user.skills = []
          if (!this.user.industries) this.user.industries = []
          if (!this.user.services) this.user.services = []

          if (this.user.user_skills) {
            var skillsIds = this.user.user_skills.map(function (s) { return s.skill_id })
            this.user.skills = skillsIds
          }
          if (this.user.user_industries) {
            var industriesIds = this.user.user_industries.map(function (i) { return i.industry_id })
            this.user.industries = industriesIds
          }
          if (this.user.user_services) {
            var servicesIds = this.user.user_services.map(function (s) { return s.service_id })
            this.user.services = servicesIds
          }
        } else {
          this.$router.push({ name: "Users" })
        }
      }).catch(() => {
        this.$router.push({ name: "Users" })
      });

      // this.signature = new SignaturePad(this.$refs.signatureCanvas);
      // this.signature.dotSize = 0.2

      this.setSkills();
      this.setIndustries();
      this.setServices();

    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader title="Update Partner" />
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Update Partner</h4>
          </div>

          <!-- end card header -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="nameInput" class="form-label">{{ user.role_id == 4 ? 'Name of authorised person' :
                      $t('user.name') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="text" class="form-control" id="nameInput" :placeholder="$t('user.enter-your-name')"
                      v-model="user.name" :class="{
                        'is-invalid': isSubmited && v$.user.name.$error,
                      }" />
                    <div v-for="(item, index) in v$.user.name.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="leaveemails" class="form-label">{{ $t('user.email') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="email" class="form-control" id="leaveemails" :placeholder="$t('user.enter-your-email')"
                      v-model="user.email" :class="{
                        'is-invalid': isSubmited && v$.user.email.$error,
                      }" />
                    <div v-for="(item, index) in v$.user.email.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="phone" class="form-label">{{ $t('user.phone') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="text" class="form-control" id="phone" :placeholder="$t('user.enter-your-phone')"
                      v-model="user.phone" :class="{
                        'is-invalid': isSubmited && v$.user.phone.$error,
                      }" />
                    <div v-for="(item, index) in v$.user.phone.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="password" class="form-label">{{ $t('user.password') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="text" class="form-control" id="password" :placeholder="$t('user.enter-your-password')"
                      v-model="user.visible_password" :class="{
                        'is-invalid': isSubmited && v$.user.visible_password.$error,
                      }" />
                    <div v-for="(item, index) in v$.user.visible_password.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="qualification" class="form-label">Qualification</label>
                  </div>
                  <div class="col-lg-9">
                    <select class="form-select" aria-label="Default select example" v-model="user.qualification"
                      id="qualification" :class="{
                        'is-invalid': isSubmited && v$.user.qualification.$error,
                      }">
                      <option disabled value="">--Select--</option>
                      <option value="Chartered Accountant">Chartered Accountant</option>
                      <option value="Certified Public Accountant(CPA)">Certified Public Accountant(CPA)</option>
                      <option value="Company Secretary">Company secretary</option>
                      <option value="Cost Accountant">Cost accountant</option>
                      <option value="Advocate">Advocate</option>

                      <option value="BBA_MBA" v-if="user.role_id == 5">BBA/ MBA</option>
                      <option value="M COM" v-if="user.role_id == 5">M COM</option>
                      <option value="B COM" v-if="user.role_id == 5">B COM</option>

                      <option value="IRS" v-if="user.role_id == 4">IRS</option>
                      <option value="Land and Building" v-if="user.role_id == 4">Registered Valuer- Land and Building
                      </option>
                      <option value="Securities consultant" v-if="user.role_id == 4">Registered Valuer- Securities
                        consultant</option>

                      <option value="B COM">Other</option>
                    </select>

                    <div v-for="(item, index) in v$.user.qualification.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="date_of_birth" class="form-label">Date of Birth </label>
                  </div>
                  <div class="col-lg-9">
                    <input type="date" class="form-control" v-model="user.date_of_birth" id="date_of_birth" :class="{
                      'is-invalid': isSubmited && v$.user.date_of_birth.$error,
                    }" placeholder="Enter Date of Birth" />
                    <div v-for="(item, index) in v$.user.date_of_birth.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="address" class="form-label">{{ $t('user.address') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <textarea class="form-control" id="address" rows="3" :placeholder="$t('user.enter-your-address')"
                      v-model="user.address"></textarea>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="city" class="form-label">{{ $t('user.city') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="text" class="form-control" id="city" :placeholder="$t('user.enter-city')"
                      v-model="user.city" />
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="state" class="form-label">{{ $t('user.state') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="text" class="form-control" id="state" :placeholder="$t('user.enter-state')"
                      v-model="user.state" />
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="zipcode" class="form-label">{{ $t('user.zip') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="text" class="form-control" id="zipcode" :placeholder="$t('user.enter-zip')"
                      v-model="user.zip" />
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="country" class="form-label">{{ $t('user.country') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <Multiselect v-model="user.country" :class="{
                      'is-invalid': isSubmited && v$.user.country.$error,
                    }" :close-on-select="true" :create-option="true" :clearable="false" :searchable="true" :options="[
  { value: 'india', label: 'India' },
  { value: 'uae', label: 'UAE' },
]" />
                    <div v-for="(item, index) in v$.user.country.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="bio" class="form-label">Bio</label>
                  </div>
                  <div class="col-lg-9">
                    <textarea type="text" class="form-control" rows="5" v-model="user.bio" id="bio" :class="{
                      'is-invalid': isSubmited && v$.user.bio.$error,
                    }" placeholder="Bio"></textarea>
                    <div class="text-end" v-if="user.bio?.length > 0">{{ 700 - user.bio.length }} character left</div>
                    <div v-for="(item, index) in v$.user.bio.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="years_of_experience" class="form-label">Years of Experience</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="text" class="form-control" v-model="user.years_of_experience" id="years_of_experience"
                      :class="{
                        'is-invalid': isSubmited && v$.user.years_of_experience.$error,
                      }" placeholder="Enter Years of Experience" />
                    <div v-for="(item, index) in v$.user.years_of_experience.$errors" :key="index"
                      class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="pan_name" class="form-label">Name as per PAN</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="text" class="form-control" v-model="user.pan_name" id="pan_name" :class="{
                      'is-invalid': isSubmited && v$.user.pan_name.$error,
                    }" placeholder="Enter PAN name" />
                    <div v-for="(item, index) in v$.user.pan_name.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="pan_number" class="form-label">PAN No.</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="text" class="form-control" v-model="user.pan_number" id="pan_number" :class="{
                      'is-invalid': isSubmited && v$.user.pan_number.$error,
                    }" placeholder="Enter PAN no." />
                    <div v-for="(item, index) in v$.user.pan_number.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="gst_number" class="form-label">GST No.</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="text" class="form-control" v-model="user.gst_number" id="gst_number" :class="{
                      'is-invalid': isSubmited && v$.user.gst_number.$error,
                    }" placeholder="Enter GST no." />
                    <div v-for="(item, index) in v$.user.gst_number.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <!-- <div class="row mb-4">
                  <div class="col-lg-3">
                    <label class="form-label"
                      >Regsitration Type</label
                    >
                  </div>
                  <div class="col-lg-9">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <input type="radio" id="consultant" name="role" v-model="user.role_id" :value="4">
                        <label for="consultant" class="form-label mb-0 mx-2">Consultant</label>
                      </div>
                      <div class="d-flex align-items-center mx-2">
                        <input type="radio" id="accountant" name="role" v-model="user.role_id" :value="5">
                        <label for="accountant" class="form-label mb-0 mx-2">Qualified Accountant</label>
                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label class="form-label">Passport Photo</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="file" id="myFile" name="filename" @change="changeFile($event, 'passport')">
                    <a :href="`${baseURl}/${user.passport_photo}`" target="_blank" v-if="user.passport_photo">View
                      Passport Size Photo</a>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label class="form-label">Professional ID Card:</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="file" id="myFile" name="filename" @change="changeFile($event, 'aadhar')">
                    <a :href="`${baseURl}/${user.aadhar_card}`" target="_blank" v-if="user.aadhar_card">View Aadhar
                      Card</a>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label class="form-label">PAN No Upoad</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="file" id="myFile" name="filename" @change="changeFile($event, 'pan')">
                    <a :href="`${baseURl}/${user.pan_photo}`" target="_blank" v-if="user.pan_photo">View PAN</a>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label class="form-label">GST Upload</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="file" id="myFile" name="filename" @change="changeFile($event, 'gst')">
                    <a :href="`${baseURl}/${user.gst_photo}`" target="_blank" v-if="user.gst_photo">View GST</a>
                  </div>
                </div>

              </div>

              <div class="col-6">

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label class="form-label">{{ $t('user.role') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <div class="mt-4 mt-lg-0">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="role-radio" id="consultant" value="4"
                          v-model="user.role_id" :class="{
                            'is-invalid': isSubmited && v$.user.role_id.$error,
                          }" />
                        <label class="form-check-label" for="user">Consultant </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="role-radio" id="qa" value="5"
                          v-model="user.role_id" :class="{
                            'is-invalid': isSubmited && v$.user.role_id.$error,
                          }" />
                        <label class="form-check-label" for="user">QA</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label class="form-label">Signature</label>
                  </div>
                  <div class="col-lg-9" v-show="user.isUpdateSignature == true || !user.signature">
                    <!-- <canvas ref="signatureCanvas" id="signatureCanvas" class="signature-pad col-md-6" />
                    <button class="
                          btn
                          btn-soft-dark
                          align-items-center
                          d-flex
                          justify-content-center
                        " type="button" @click="signature.clear()">
                      Clear Signature
                    </button> -->

                    <input type="file" id="myFile" name="filename" @change="changeFile($event, 'signature')">

                  </div>
                  <div class="col-lg-9" v-if="!user.isUpdateSignature && user.signature">
                    <div style="width: fit-content; max-width: 250px; aspect-ratio: 16/9;" class="signature-pad position-relative">
                      <span @click="user.isUpdateSignature = true"
                        class="position-absolute top-0 start-100 d-grid translate-middle bg-danger text-white rounded-circle"
                        style="height: 20px; width: 20px; place-items: center; cursor: pointer;">x
                      </span>
                      <img :src="`${baseURl}/${user.signature}`" style="width: 100%;" :alt="`${baseURl}/${user.signature}`">
                    </div>
                  </div>

                </div>
                <div class="row mb-4" v-if="user.role_id == 5">
                  <div class="col-lg-3">
                    <label for="tier" class="form-label">Tier</label>
                  </div>
                  <div class="col-lg-9">
                    <div class="mt-4 mt-lg-0">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="tier1-radio" id="tier1" value="1"
                          v-model="user.tier" />
                        <label class="form-check-label" for="super-admin">Tier 1</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="tier2-radio" id="tier2" value="2"
                          v-model="user.tier" />
                        <label class="form-check-label" for="super-admin">Tier 2</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="tier3-radio" id="tier3" value="3"
                          v-model="user.tier" />
                        <label class="form-check-label" for="super-admin">Tier 3</label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="tier4-radio" id="tier4" value="4"
                          v-model="user.tier" />
                        <label class="form-check-label" for="super-admin">Tier 4</label>
                      </div>

                    </div>
                  </div>
                </div>

                <div v-if="user.role_id == 4">
                  <!-- v-else-if="user.role_id == 5" -->
                  <div class="row mb-4">
                    <div class="col-lg-3">
                      <label for="charges" class="form-label">Consultancy Charges</label>
                    </div>
                    <div class="col-lg-9">
                      <div class="d-flex align-items-center mb-3">
                        <input class="form-control small-input me-2" type="number"
                          v-model="user.consultancy_charge_fifthteen"> / 15 Minutes
                      </div>
                      <div class="d-flex align-items-center mb-3">
                        <input class="form-control small-input me-2" type="number"
                          v-model="user.consultancy_charge_thirty"> / 30 Minutes
                      </div>
                      <div class="d-flex align-items-center mb-3">
                        <input class="form-control small-input me-2" type="number"
                          v-model="user.consultancy_charge_forty"> / 45 Minutes
                      </div>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-lg-3">
                      <label for="skill" class="form-label">Select Skills</label>
                    </div>
                    <div class="col-lg-9">
                      <Multiselect max="3" mode="multiple" id="skill" v-model="user.skills" :preserve-search="true"
                        :clear-on-select="true" :clearable="false" :searchable="true" :create-option="true"
                        :options="skillsOptions" />
                      <div class="mt-1">
                        <span v-for="(selectedSkill, index) in showSelectedArr(skillsOptions, user.skills)"
                          :key="index">{{ selectedSkill.label }} <span
                            v-if="!(showSelectedArr(skillsOptions, user.skills).length == index + 1)">,</span></span>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-lg-3">
                      <label for="indus" class="form-label">Select Industries</label>
                    </div>
                    <div class="col-lg-9">
                      <Multiselect max="3" mode="multiple" id="indus" :clear-on-select="false" v-model="user.industries"
                        :close-on-select="true" :create-option="true" :clearable="false" :searchable="true"
                        :options="industryOptions" />
                      <div class="mt-1">
                        <span v-for="(selectedSkill, index) in showSelectedArr(industryOptions, user.industries)"
                          :key="index">{{ selectedSkill.label }} <span
                            v-if="!(showSelectedArr(industryOptions, user.industries).length == index + 1)">,</span></span>
                      </div>
                    </div>
                  </div>



                </div>

                <div v-else-if="user.role_id == 5">

                  <div class="row mb-4">
                    <div class="col-lg-3">
                      <label for="service" class="form-label">Select Service</label>
                    </div>
                    <div class="col-lg-9">
                      <Multiselect mode="multiple" id="service" :clear-on-select="false" v-model="user.services"
                        :close-on-select="true" :create-option="true" :clearable="false" :searchable="true"
                        :options="serviceOptions" />

                      <div class="mt-1">
                        <span v-for="(selectedSkill, index) in showSelectedArr(serviceOptions, user.services)"
                          :key="index">{{ selectedSkill.label }} <span
                            v-if="!(showSelectedArr(serviceOptions, user.services).length == index + 1)">,</span></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label class="form-label">Qualification Document</label>
                  </div>
                  <div class="col-lg-9">
                    <input type="file" id="myFile" name="filename" @change="changeFile($event, 'qaDoc')">
                  </div>
                </div>



                <div class="row mb-4" v-if="userData.role_id == 1">
                  <div class="col-lg-3">
                    <label for="remark" class="form-label">Remark</label>
                  </div>
                  <div class="col-lg-9">
                    <textarea class="form-control" id="remark" rows="3" placeholder="Remark"
                      v-model="user.remark"></textarea>
                  </div>
                </div>

              </div>
            </div>
            <div class="text-end float-end">
              <button class="btn btn-primary align-items-center d-flex justify-content-center" :disabled="disabled"
                @click="updateUser">
                <!-- {{ $t('user.update-user') }} -->
                Update Partner
                <div class="spinner-border loader-setup" role="status" v-if="loader">
                  <span class="sr-only">{{ $t('t-loading') }}</span>
                </div>
              </button>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <!-- end col -->
    </div>

    <!--end row-->
  </Layout>
</template>
<style>
.multiselect.is-invalid {
  border: 1px solid red !important;
}

.signature-pad {
  border: 1px solid grey;
  margin-left: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 0px;
}

.small-input {
  width: 100px;
}
</style>